.handle {
	width: 100%;
	height: 830px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.div1 {
	height: 40px;
	width: 70%;
	text-align: center;
	box-shadow: 0px 0px 16px 0px rgba(124, 133, 145, 0.16);
	border-radius: 8px;
	margin: 20px 0 20px 0;
	background: #FFFFFF;
}

.div2 {
	height: 90%;
	width: 70%;
	padding: 20px 0 20px 0;
	box-shadow: 0px 0px 16px 0px rgba(124, 133, 145, 0.16);
	border-radius: 8px;
	background: #FFFFFF;
}

.div3 {
	height: 90%;
	width: 70%;
	box-shadow: 0px 0px 16px 0px rgba(124, 133, 145, 0.16);
	border-radius: 8px;
	background: #FFFFFF;
}

.fontStyle {
	color: red;
	font-size: 20px;
}

.fontStyle1 {
	font-size: 18px;
}

.textStyle {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.textStyle1 {
	margin-top: 10px;
}

.iphHandle {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.iphDiv1 {
	height: 20%;
	width: 70%;
	text-align: center;
	box-shadow: 0px 0px 16px 0px rgba(124, 133, 145, 0.16);
	border-radius: 8px;
	margin: 20px 0 20px 0;
	background: #FFFFFF;
}

.iphDiv2 {
	height: 90%;
	width: 70%;
	padding: 20px 0 20px 0;
	box-shadow: 0px 0px 16px 0px rgba(124, 133, 145, 0.16);
	border-radius: 8px;
	background: #FFFFFF;

}

.iphImage {
	height: 60%;
	width: 55%;
}

.divImage {
	height: 500px;
	width: 55%;
}