.shopMall {
    width: 100%;
   display: flex;
   flex-direction: column;
   justify-content:center;
   align-items:center;
}
.queryGoods {
	width: 100%;
	height: 50px;
	display: flex;
	flex-direction: row;
	justify-content:center;
	align-items:center;
	padding: 0 2% 0 15%;
}
.queryGoods1{
	width: 100%;
	height: 50px;
	display: flex;
	flex-direction: row;
	justify-content:center;
	align-items:center;
	padding: 0 5% 0 60%;
}
.input{
	height: 30px;
	width: 60%;
	margin: 0 10px 0 0 ;
	border-radius:12px;
	outline:none;
	border-width:1px;
}
.button{
	border-radius:12px;
	border-width:1px;
	width:20%;
	height: 30px;
	
}

.modal{
        font-size:12px;
        padding:20px;
        width:520px;
        height:200px;
        margin: '100px auto 0';
        backgroundColor: '#fff';
        borderRadius: 4px;
        overflow: 'hidden';
        textAlign: 'center';
}


